:local(.lista) {
    width: 100%;
}

:local(.elementoLista) {
    width: inherit;
    display: inherit;
}

:local(.contenedorItem) > span {
    font-size: 1em;
}

:local(.elementoListaInline) {
    margin-left: 30px !important;
    width: auto !important;
}

:local(.separadorInline) {
    margin-left: 30px !important;
}
