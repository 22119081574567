.cc_dialog {
    left: 0;
    z-index: 1000;
    margin-left: 200px;
    padding: 1.5em !important;
    margin-top: 68px;
    margin-bottom: -68px;

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 768px) {
    .cc_dialog {
        margin-top: 52px !important;
        margin-bottom: -52px !important;
        margin-left: 0;
    }
}
