@charset "UTF-8";

/* https://minicss.org/docs#grid */

/* Core module CSS variable definitions */
:root {
    --universal-padding: 0.5rem;
}

/*
  Definitions for the grid system, cards and containers.
*/
.container {
    margin: 0 auto;
    padding: 0 calc(1.5 * 0.5rem);
    padding: 0 calc(1.5 * var(--universal-padding));
}

.row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
}

.col-sm,
[class^='col-sm-'],
[class^='col-sm-offset-'],
.row[class*='cols-sm-'] > * {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding: 0 calc(0.5rem / 2);
    padding: 0 calc(var(--universal-padding) / 2);
}

.col-sm,
.row.cols-sm > * {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
}

.col-sm-1,
.row.cols-sm-1 > * {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
}

.col-sm-offset-0 {
    margin-left: 0;
}

.col-sm-2,
.row.cols-sm-2 > * {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
}

.col-sm-offset-1 {
    margin-left: 8.33333%;
}

.col-sm-3,
.row.cols-sm-3 > * {
    max-width: 25%;
    flex-basis: 25%;
}

.col-sm-offset-2 {
    margin-left: 16.66667%;
}

.col-sm-4,
.row.cols-sm-4 > * {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
}

.col-sm-offset-3 {
    margin-left: 25%;
}

.col-sm-5,
.row.cols-sm-5 > * {
    max-width: 41.66667%;
    flex-basis: 41.66667%;
}

.col-sm-offset-4 {
    margin-left: 33.33333%;
}

.col-sm-6,
.row.cols-sm-6 > * {
    max-width: 50%;
    flex-basis: 50%;
}

.col-sm-offset-5 {
    margin-left: 41.66667%;
}

.col-sm-7,
.row.cols-sm-7 > * {
    max-width: 58.33333%;
    flex-basis: 58.33333%;
}

.col-sm-offset-6 {
    margin-left: 50%;
}

.col-sm-8,
.row.cols-sm-8 > * {
    max-width: 66.66667%;
    flex-basis: 66.66667%;
}

.col-sm-offset-7 {
    margin-left: 58.33333%;
}

.col-sm-9,
.row.cols-sm-9 > * {
    max-width: 75%;
    flex-basis: 75%;
}

.col-sm-offset-8 {
    margin-left: 66.66667%;
}

.col-sm-10,
.row.cols-sm-10 > * {
    max-width: 83.33333%;
    flex-basis: 83.33333%;
}

.col-sm-offset-9 {
    margin-left: 75%;
}

.col-sm-11,
.row.cols-sm-11 > * {
    max-width: 91.66667%;
    flex-basis: 91.66667%;
}

.col-sm-offset-10 {
    margin-left: 83.33333%;
}

.col-sm-12,
.row.cols-sm-12 > * {
    max-width: 100%;
    flex-basis: 100%;
}

.col-sm-offset-11 {
    margin-left: 91.66667%;
}

.col-sm-normal {
    order: initial;
}

.col-sm-first {
    order: -999;
}

.col-sm-last {
    order: 999;
}

@media screen and (min-width: 768px) {
    .col-md,
    [class^='col-md-'],
    [class^='col-md-offset-'],
    .row[class*='cols-md-'] > * {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding: 0 calc(0.5rem / 2);
        padding: 0 calc(var(--universal-padding) / 2);
    }
    .col-md,
    .row.cols-md > * {
        max-width: 100%;
        flex-grow: 1;
        flex-basis: 0;
    }
    .col-md-1,
    .row.cols-md-1 > * {
        max-width: 8.33333%;
        flex-basis: 8.33333%;
    }
    .col-md-offset-0 {
        margin-left: 0;
    }
    .col-md-2,
    .row.cols-md-2 > * {
        max-width: 16.66667%;
        flex-basis: 16.66667%;
    }
    .col-md-offset-1 {
        margin-left: 8.33333%;
    }
    .col-md-3,
    .row.cols-md-3 > * {
        max-width: 25%;
        flex-basis: 25%;
    }
    .col-md-offset-2 {
        margin-left: 16.66667%;
    }
    .col-md-4,
    .row.cols-md-4 > * {
        max-width: 33.33333%;
        flex-basis: 33.33333%;
    }
    .col-md-offset-3 {
        margin-left: 25%;
    }
    .col-md-5,
    .row.cols-md-5 > * {
        max-width: 41.66667%;
        flex-basis: 41.66667%;
    }
    .col-md-offset-4 {
        margin-left: 33.33333%;
    }
    .col-md-6,
    .row.cols-md-6 > * {
        max-width: 50%;
        flex-basis: 50%;
    }
    .col-md-offset-5 {
        margin-left: 41.66667%;
    }
    .col-md-7,
    .row.cols-md-7 > * {
        max-width: 58.33333%;
        flex-basis: 58.33333%;
    }
    .col-md-offset-6 {
        margin-left: 50%;
    }
    .col-md-8,
    .row.cols-md-8 > * {
        max-width: 66.66667%;
        flex-basis: 66.66667%;
    }
    .col-md-offset-7 {
        margin-left: 58.33333%;
    }
    .col-md-9,
    .row.cols-md-9 > * {
        max-width: 75%;
        flex-basis: 75%;
    }
    .col-md-offset-8 {
        margin-left: 66.66667%;
    }
    .col-md-10,
    .row.cols-md-10 > * {
        max-width: 83.33333%;
        flex-basis: 83.33333%;
    }
    .col-md-offset-9 {
        margin-left: 75%;
    }
    .col-md-11,
    .row.cols-md-11 > * {
        max-width: 91.66667%;
        flex-basis: 91.66667%;
    }
    .col-md-offset-10 {
        margin-left: 83.33333%;
    }
    .col-md-12,
    .row.cols-md-12 > * {
        max-width: 100%;
        flex-basis: 100%;
    }
    .col-md-offset-11 {
        margin-left: 91.66667%;
    }
    .col-md-normal {
        order: initial;
    }
    .col-md-first {
        order: -999;
    }
    .col-md-last {
        order: 999;
    }
}

@media screen and (min-width: 1280px) {
    .col-lg,
    [class^='col-lg-'],
    [class^='col-lg-offset-'],
    .row[class*='cols-lg-'] > * {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding: 0 calc(0.5rem / 2);
        padding: 0 calc(var(--universal-padding) / 2);
    }
    .col-lg,
    .row.cols-lg > * {
        max-width: 100%;
        flex-grow: 1;
        flex-basis: 0;
    }
    .col-lg-1,
    .row.cols-lg-1 > * {
        max-width: 8.33333%;
        flex-basis: 8.33333%;
    }
    .col-lg-offset-0 {
        margin-left: 0;
    }
    .col-lg-2,
    .row.cols-lg-2 > * {
        max-width: 16.66667%;
        flex-basis: 16.66667%;
    }
    .col-lg-offset-1 {
        margin-left: 8.33333%;
    }
    .col-lg-3,
    .row.cols-lg-3 > * {
        max-width: 25%;
        flex-basis: 25%;
    }
    .col-lg-offset-2 {
        margin-left: 16.66667%;
    }
    .col-lg-4,
    .row.cols-lg-4 > * {
        max-width: 33.33333%;
        flex-basis: 33.33333%;
    }
    .col-lg-offset-3 {
        margin-left: 25%;
    }
    .col-lg-5,
    .row.cols-lg-5 > * {
        max-width: 41.66667%;
        flex-basis: 41.66667%;
    }
    .col-lg-offset-4 {
        margin-left: 33.33333%;
    }
    .col-lg-6,
    .row.cols-lg-6 > * {
        max-width: 50%;
        flex-basis: 50%;
    }
    .col-lg-offset-5 {
        margin-left: 41.66667%;
    }
    .col-lg-7,
    .row.cols-lg-7 > * {
        max-width: 58.33333%;
        flex-basis: 58.33333%;
    }
    .col-lg-offset-6 {
        margin-left: 50%;
    }
    .col-lg-8,
    .row.cols-lg-8 > * {
        max-width: 66.66667%;
        flex-basis: 66.66667%;
    }
    .col-lg-offset-7 {
        margin-left: 58.33333%;
    }
    .col-lg-9,
    .row.cols-lg-9 > * {
        max-width: 75%;
        flex-basis: 75%;
    }
    .col-lg-offset-8 {
        margin-left: 66.66667%;
    }
    .col-lg-10,
    .row.cols-lg-10 > * {
        max-width: 83.33333%;
        flex-basis: 83.33333%;
    }
    .col-lg-offset-9 {
        margin-left: 75%;
    }
    .col-lg-11,
    .row.cols-lg-11 > * {
        max-width: 91.66667%;
        flex-basis: 91.66667%;
    }
    .col-lg-offset-10 {
        margin-left: 83.33333%;
    }
    .col-lg-12,
    .row.cols-lg-12 > * {
        max-width: 100%;
        flex-basis: 100%;
    }
    .col-lg-offset-11 {
        margin-left: 91.66667%;
    }
    .col-lg-normal {
        order: initial;
    }
    .col-lg-first {
        order: -999;
    }
    .col-lg-last {
        order: 999;
    }
}

@media screen and (max-width: 1280px) {
    html:before {
        background-size: 400px;
    }
}

@media screen and (max-width: 768px) {
    html:before {
        background-size: 300px;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

body:before,
body:after {
    position: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

body:before {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 500px;
    background-position-x: calc(100% - 50px);
    background-position-y: calc(100% - 50px);
    z-index: -10;
}

body:after {
    opacity: 0.6;
    background-color: inherit;
    z-index: -5;
}

._2aJOiyEQ8jRjGoTg6vcd4Q {
    height: calc(100vh - 64px);
    margin-top: 64px;
    will-change: transition;
    transition: margin 350ms ease-in-out;
    position: relative;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

@media screen and (min-width: 960px) {
    ._1M26kHqirnn7L2M4vAgoQA {
        margin-left: 200px;
    }
}

@media screen and (max-width: 768px) {
    ._2aJOiyEQ8jRjGoTg6vcd4Q {
        height: calc(100vh - 48px);
        margin-top: 48px;
    }
}

._1wgiNJMTENOspowGTc0bX7,
._31frZmsIcrPqR5KU_DVMCY,
._1xNpaEhwBTK1gSEqRrtvZX > div > nav {
    width: 200px;
}

._1xNpaEhwBTK1gSEqRrtvZX {
    z-index: 1500 !important;
}

._31frZmsIcrPqR5KU_DVMCY {
    will-change: transition;
    transition: margin 350ms ease-in-out;
    position: absolute;
    height: calc(100% - 64px);
}

._1wgiNJMTENOspowGTc0bX7 {
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}

._2FM2hy7fOMHhcw3raJF2Ss {
    margin-left: -200px;
}

._2UfjdewzaGUiGg69T_IYyN {
    width: 100%;
}

.mzo-hNX29yhg1nxKTIZTX {
    width: inherit;
    display: inherit;
}

._3Xf5xb_QhW2USTOYJ2m5FZ > span {
    font-size: 1em;
}

._1ZcuZuO04udQLxAVUSU6WQ {
    margin-left: 30px !important;
    width: auto !important;
}

._3IiENe3OYk7_jCgbpAzamz {
    margin-left: 30px !important;
}

._3gWe333Ljxn4Y-zQKzoKxi {
    padding: 0px 1.5px;
    box-sizing: content-box;
}

._21hUKZtGWTB0LmW4JqxMji {
    z-index: 1600 !important;
}

._3hG98nhoNnYVRBvuQxkvHd {
    position: absolute;
}

._2-L7S1GLyygatc_lRn75V {
    text-shadow: 1px 1px 0.5px rgb(34, 34, 34);
}

._2buabKszQ3aOw-zEr2Bh_D {
    text-shadow: 1px 1px 0.5px rgb(34, 34, 34, 0.3);
}

button ._38fFCgQyo4wWXe9ADF-pe1 {
    font-size: 1.4rem;
}
._29vQluX0pmOFGdoa9Ttxv2 {
    z-index: 1250 !important;
}

._29vQluX0pmOFGdoa9Ttxv2 > div {
    min-height: 64px;
    max-height: 64px;
}

.FrzW4XAZXk3RaOEJS0eeA {
    justify-content: flex-end;
    display: inline-flex;
    flex: 1 1 auto;
}

._2ynvYZ98ECXCVvdl-eJGtV {
    padding: 0px !important;
}

._2ynvYZ98ECXCVvdl-eJGtV img {
    object-fit: unset;
    height: 50px;
    width: 50px;
    border-radius: 50% !important;
}

._1c6jsSH0asj746onn4TOkK {
    z-index: 1000;
}

._2pxztk-UxNJ5ONO4ptOI7M {
    z-index: 1000;
}

._2Ul9cgPZjwamnczmEh0nUh {
    flex: 1 1 auto;
    justify-content: unset;
    border: 1px solid red;
    height: 46px;
    margin: auto;
}

._2D7XoBEQugWPY-RBdBiTvf {
    height: 40px;
    margin: auto;
    cursor: pointer;
    max-width: 200px;
    object-fit: scale-down;
}

.hwyror-uEaabwmp-calJ {
    background-image: linear-gradient(135deg, #5cc8ec 0%, #d45db0 50%, #f45e2c 100%);
}

._14OyQa9wtso2z4hk65etY6 {
    margin-left: -12px !important;
    margin-right: 18px !important;
}

@media screen and (max-width: 960px) {
    ._2ynvYZ98ECXCVvdl-eJGtV img {
        height: 30px;
        width: 30px;
    }

    ._14OyQa9wtso2z4hk65etY6 {
        margin-right: 0px !important;
    }
}

@media screen and (max-width: 768px) {
    ._29vQluX0pmOFGdoa9Ttxv2 > div {
        min-height: 48px;
        max-height: 48px;
    }

    ._2Ul9cgPZjwamnczmEh0nUh {
        height: 35px;
    }

    ._2D7XoBEQugWPY-RBdBiTvf {
        height: 35px;
    }
}

.cc_dialog {
    left: 0;
    z-index: 1000;
    margin-left: 200px;
    padding: 1.5em !important;
    margin-top: 68px;
    margin-bottom: -68px;

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 768px) {
    .cc_dialog {
        margin-top: 52px !important;
        margin-bottom: -52px !important;
        margin-left: 0;
    }
}

