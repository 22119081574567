@media screen and (max-width: 1280px) {
    html:before {
        background-size: 400px;
    }
}

@media screen and (max-width: 768px) {
    html:before {
        background-size: 300px;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

body:before,
body:after {
    position: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

body:before {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 500px;
    background-position-x: calc(100% - 50px);
    background-position-y: calc(100% - 50px);
    z-index: -10;
}

body:after {
    opacity: 0.6;
    background-color: inherit;
    z-index: -5;
}
