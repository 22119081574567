:local(.aplicacion) {
    height: calc(100vh - 64px);
    margin-top: 64px;
    will-change: transition;
    transition: margin 350ms ease-in-out;
    position: relative;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

@media screen and (min-width: 960px) {
    :local(.aplicacionNavAbierto) {
        margin-left: 200px;
    }
}

@media screen and (max-width: 768px) {
    :local(.aplicacion) {
        height: calc(100vh - 48px);
        margin-top: 48px;
    }
}
