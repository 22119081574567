:local(.panelPC),
:local(.contenedorPanelPC),
:local(.panelLateralMovil) > div > nav {
    width: 200px;
}

:local(.panelLateralMovil) {
    z-index: 1500 !important;
}

:local(.contenedorPanelPC) {
    will-change: transition;
    transition: margin 350ms ease-in-out;
    position: absolute;
    height: calc(100% - 64px);
}

:local(.panelPC) {
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}

:local(.panelPCCerrado) {
    margin-left: -200px;
}
